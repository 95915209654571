import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import FiftyFifty from '../components/fiftyfifty'
import BgImage from "../components/bgImage"

const FireRatedWindows = (props) => {
  return(
  <section>
    <SEO title="Fire rated windows" description='Reliable profile system for fire protection. Our fire rated windows and fire rated doors comply with all EU standards. Fully certified and are CE marked.' />
    <BgImage 
        image={props.data.mainImage.childImageSharp.fluid}
        text="Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings. Windows and doors crafted from our profiles are often the most prominent feature in prestigious estates and custom homes."
        title="Fire Rated Windows"
        subtitle="Minimalistic modern design"
    ></BgImage>
    <FiftyFifty  data={props.data.allProducts} type="products"/>   
  </section>
  )
}
export const query  = graphql`
{
  mainImage: file(relativePath: {regex: "/steelWindows/"}) {
    childImageSharp {
      fluid(maxWidth: 1980) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  
  allProducts(filter: {fire: {eq: true }}) {
    edges {
      node {
        name
        intro
        localImage {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand {
          brand
        }
        id
      }
    }
  }
}
`

export default FireRatedWindows
